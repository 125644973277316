import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import getValidationErrors from "../../utils/getValidationErrors";
import InputWithLabel from "../../components/InputWithLabel";
import ConfirmationModal from "../../components/ConfirmationModal";
import { format, parseISO } from "date-fns";

const ManageFilteredSalesforceEventEditions = () => {
  const { token } = useSelector((state) => state.auth);
  const [salesforceEventEditions, setSalesforceEventEditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEventEditionCode, setSelectedEventEditionCode] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    event_edition_code: Yup.string().nullable(),
    event_edition_name: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        if (loading) {
          return;
        }

        setLoading(true);

        api
          .patch(
            "/salesforce-event-editions",
            {
              eventEditionCode:
                data.event_edition_code === "" ? null : data.event_edition_code,
              eventEditionName:
                data.event_edition_name === "" ? null : data.event_edition_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) =>
            setSalesforceEventEditions(result.data.output))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  useEffect(() => {
    getAllSalesforceEventEditions();
  }, [addToast, handleLoading, intl, token]);

  const getAllSalesforceEventEditions = useCallback(() => {
    handleLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .patch(
        `/salesforce-event-editions`,
        { eventEditionCode: null, eventEditionName: null },
        config
      )
      .then((result) => {
        setSalesforceEventEditions(result.data.output);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event_edition_code");
    formRef.current.setFieldValue("event_edition_name");
    getAllSalesforceEventEditions();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/salesforce-event-editions/${selectedEventEditionCode}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.salesforce_event_edition_deleted",
          }),
        });
        setSelectedEventEditionCode(null);
        formRef.current.submitForm();
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        setModalIsOpen(false);
        handleLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "edition" }),
        selector: (row) => row.eventEditionCode,
        width: "150px",
      },
      {
        name: intl.formatMessage({ id: "name" }),
        selector: (row) => row.eventEditionName,
        allowOverflow: true,
        wrap: true,
        sortable: true,
        maxWidth: "400px",
      },
      {
        name: intl.formatMessage({ id: "last_update" }),
        selector: (row) => row.lastIntegration,
        sortable: true,
        format: (row) =>
          row.lastIntegration
            ? format(parseISO(row.lastIntegration), "dd/MM/yyyy HH:mm")
            : "",
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
      },
      {
        name: intl.formatMessage({ id: "returned_opportunities" }),
        selector: (row) => row.returnedOpportunities,
        width: "230px",
      },
      {
        name: intl.formatMessage({ id: "updated_opportunities" }),
        selector: (row) => row.updatedOpportunities,
        width: "230px",
      },
      {
        width: "125px",
        cell: (row) => (
          <LinkButton to={`/manage-salesforce-event-editions/${row.eventEditionCode}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
      {
        width: "125px",
        cell: (row) => (
          <Badge
            onClick={() => {
              setSelectedEventEditionCode(row.eventEditionCode);
              setModalIsOpen(true);
            }}
            color="#cc0000"
            text={intl.formatMessage({ id: "delete" })}
          />
        ),
        right: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.salesforce_integration_event_editions"
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_event_edition" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
                <BadgeContainer
                  onClick={() => history.push(
                    "/manage-salesforce-event-editions/new"
                  )}
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "add" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="event_edition_code"
                  labelWidth="150px"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "edition" })}
                  placeholder={intl.formatMessage({ id: "edition" })}
                />
                <InputWithLabel
                  name="event_edition_name"
                  label={intl.formatMessage({
                    id: "name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "name",
                  })}
                  labelWidth="150px"
                  validationBlur={validationBlur}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={salesforceEventEditions}
          columns={columns}
          hasPagination
          progressPending={loading}
        />
      </Container>
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default ManageFilteredSalesforceEventEditions;
